<template>
  <div>
    <button
      v-for="(lang, key) in availableLocales"
      v-bind:key="key"
      v-bind:class="['btn mr-2', value == lang ? 'btn-indigo' : 'btn-light']"
      v-on:click="onChange(lang)"
    >
      <lang-flag v-bind:iso="lang" />
    </button>
  </div>
</template>

<script>
import LangFlag from "vue-lang-code-flags";
import { mapState } from "vuex";

export default {
  name: "Language",
  components: { LangFlag },
  props: {
    value: {
      default: "tr",
    },
  },
  model: {
    prop: "value",
  },
  data() {
    return { langs: [] };
  },
  methods: {
    onChange(lang) {
      if (this.value !== lang) {
        this.$emit("input", lang);
      }
    },
  },
  computed: {
    ...mapState(["session"]),
    availableLocales() {
      let locales = this.session?.config["site.available_locales"];

      if (locales) {
        locales = JSON.parse(locales);
      } else {
        locales = ["tr"];
      }

      return locales;
    },
  },
};
</script>

<style lang="scss" scoped>
.lang-button {
  display: flex;
  justify-content: center;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
}

.dropdown-item {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.dropdown-item span {
  margin-left: 8px;
}
</style>